import React from "react";
import SEO from "../components/seo";
import * as global from "../styles/global.module.scss";

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <div className={global.pageWrapper}>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </>
);

export default NotFoundPage;
